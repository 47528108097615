/* 24. Login */
 .basic-register {
     padding: 90px;
     border: 2px solid #eaedff;
}
 @media only screen and (min-width: 992px) and (max-width: 1200px) {
     .basic-register {
         padding: 50px;
    }
}
 @media only screen and (min-width: 768px) and (max-width: 991px) {
     .basic-register {
         padding: 40px;
    }
}
 @media (max-width: 767px) {
     .basic-register {
         padding: 30px;
    }
}
 .basic-register h3 {
     font-size: 30px;
}
 .basic-register input,
 .basic-register select
  {
     width: 100%;
     height: 60px;
     border: 2px solid #eaedff;
     color: #6f7172;
     padding: 0 20px;
     margin-bottom: 20px;
}
 .basic-register label {
     color: #222;
     display: block;
}
 .basic-register label span {
     color: #fe4536;
}
 .register-action input {
     width: inherit;
     height: auto;
}
 .register-action label {
     display: inline-block;
     margin-left: 5px;
}
 .or-divide {
     border-top: 2px solid #eaedff;
     margin: 40px 0;
     text-align: center;
     position: relative;
}
 .or-divide span,.or-register span {
     position: absolute;
     left: 0;
     right: 0;
     top: -9px;
     background: #ffffff;
     display: inline-block;
     width: 40px;
     margin: auto;
     line-height: 1;
     font-weight: 500;
}
.or-register span{
    width: 120px;
}
 @media (max-width: 767px) {
     .forgot-register {
         float: none;
    }
}
 .forgot-register span {
     color: #fe4536;
     cursor: pointer;
}
 .forgot-register span:hover {
     color: #84b77c;
}
 @media (max-width: 767px) {
     .log-rem {
         float: none;
         margin-bottom: 10px;
         display: block;
    }
}
.register_btn{
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.register_btn img{
    width: 50px;
    height: 50px;
}

#pharmacy-select {
    position: relative;
  
    ul {
        padding: 0px;
        margin: 0px;
        margin-bottom: 50px;
        max-height: 100px;
        width: 100%;
        overflow-y: auto;
        border: 1px solid darkgray;
        border-top: none;
        border-radius: 0px 0px 5px 5px;
        position: absolute;
        z-index: 1;
    
        li:hover {
            background: $highlight-gray;
        }
    
        li {
            width: 100%;
            list-style-type: none;
            padding: 5px 10px;
            border: 0.001em solid #c0c0c0;
            background: $smoke-white;
            cursor: pointer; 
        }
    }
    
    .item {
        .badge {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            white-space: normal;
            font-weight: 400;
            text-align: left;
            border-radius: 5px;
            background-color: $badge-gray;
            margin: 2px;
            color:$dark-color;
            cursor: pointer;
            .close {
                padding-left: 10px;
                padding-bottom: 5px;
                width: auto;
            }
            
              button {
                width: 145px;
              }
            
        }
    }
  }


  .keywords{
    color:$orange-red;
  }

  .alert-self-danger {
    padding:.3rem .75rem;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: .25rem;
  }
  