.file-download-link{
    color:$main-color
}

.file-downloaded-link{
    text-decoration: line-through
  }
  .file-downloaded-text{
    color:$urgent-color
  }
  .file-download-text{
    color:$download-available-color
  }