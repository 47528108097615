#dashbaord-container{
  
    // min-height: 100vh;
    max-width: 1800px;
    padding-top:100px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  
    button{
      width:100%;
      // background-color:$main-color;
    }

    .service-link{
      border:none;
    }
    .service-link:hover
    {
       background: $main-color;
      //  color: #fff;
  }

    .service-link:disabled{
      cursor: not-allowed;
      text-decoration: line-through;
    }

    .service-link:disabled:hover{
      background-color:#e8f1f7;
      color: #647589;
    }

  
  
  }
  