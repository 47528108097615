/*
* variables for the project
*/

$light-color-prev: #EAF0F1;
$light-color: #FFF;
$light-medium-color: #dddddd;
$medium-color: #0A3D62; 
$ligmid-color: #4C4B4B;
$policy-color:#ff7272;
$dark-color: #333;
$orange-red: #fa755a;


// gray
$dark-gray: #343a40;
$medium-gray: #606060;
$gray: gray;
$pale-gray: #dfdfdf;
$light-gray: #f2f2f2;

$smoke-white: #f5f5f5;
$badge-gray: $light-gray;



//#42B3E5

// $main-color:$main-color; 
$main-color:#42B3E5; 
$light-medium-color: #dddddd;
$highlight-gray: $pale-gray;

$title-gray: $medium-gray;

$download-available-color:#49be25;
// urgent message
$urgent-color:#FF3232;
// Set Text Color

$zIndexHigh:1054;