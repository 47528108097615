/* 24. Login */
.basic-forgotPassword {
    padding: 90px;
    border: 2px solid #eaedff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .basic-forgotPassword {
        padding: 50px;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .basic-forgotPassword {
        padding: 40px;
   }
}
@media (max-width: 767px) {
    .basic-forgotPassword {
        padding: 30px;
   }
}
.basic-forgotPassword h3 {
    font-size: 30px;
}
.basic-forgotPassword input,
.basic-forgotPassword select
 {
    width: 100%;
    height: 60px;
    border: 2px solid #eaedff;
    color: #6f7172;
    padding: 0 20px;
    margin-bottom: 20px;
}
.basic-forgotPassword label {
    color: #222;
    display: block;
}
.basic-forgotPassword label span {
    color: #fe4536;
}
.forgotPassword-action input {
    width: inherit;
    height: auto;
}
.forgotPassword-action label {
    display: inline-block;
    margin-left: 5px;
}
.or-divide {
    border-top: 2px solid #eaedff;
    margin: 40px 0;
    text-align: center;
    position: relative;
}
.or-divide span,.or-forgotPassword span {
    position: absolute;
    left: 0;
    right: 0;
    top: -9px;
    background: #ffffff;
    display: inline-block;
    width: 40px;
    margin: auto;
    line-height: 1;
    font-weight: 500;
}
.or-forgotPassword span{
   width: 120px;
}
@media (max-width: 767px) {
    .forgot-forgotPassword {
        float: none;
   }
}
.forgot-forgotPassword span {
    color: #fe4536;
    cursor: pointer;
}
.forgot-forgotPassword span:hover {
    color: #84b77c;
}
@media (max-width: 767px) {
    .log-rem {
        float: none;
        margin-bottom: 10px;
        display: block;
   }
}
.forgotPassword_btn{
   border: none;
   background: transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
}
.forgotPassword_btn img{
   width: 50px;
   height: 50px;
}