#edit-medical-info-page{
    padding:100px;
    h1 {
        display: flex;
        align-items: center;

        .person {
            color: gray;
            font-size: 26px;
        }
    }

   
    .badge-item{
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        max-width: 300px;
        white-space: normal;
        font-weight: 400;
        text-align: left;
        border-radius: 5px;
        background-color: #f2f2f2;
        margin: 2px;
        cursor: pointer;
    }

    .badge-light{
        color: #212529;
    }

}