#twitter-container{
    // background: #243447;
    background: black;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

@media(max-width:1200px){
    #twitter-container{
        display:block !important;
    }
}
