/* 18. FAQ */
 .faq-left-box {
     margin-bottom: 235px;
}
 .faq-pos-front {
     position: absolute;
     bottom: -235px;
     left: 57px;
}
 .faq-back-shape {
     position: absolute;
     bottom: -162px;
     left: -130px;
     z-index: -9;
}
 .faq-right-box .card {
     position: relative;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     min-width: 0;
     word-wrap: break-word;
     background-color: #fff;
     background-clip: border-box;
     border: 0;
     border-radius: 0;
     background: #F8F7FF;
     margin-bottom: 20px;
}
 .faq-right-box .card-header {
     padding: 0;
     border-bottom: 0;
     background: none;
     border-radius: 0;
}
 .faq-right-box .btn-link {
     font-weight: 400;
     color: #0f1328;
     display: block;
     font-size: 16px;
     padding: 21px 30px;
     border: 2px solid $main-color;
     text-decoration: none;
     line-height: 28px;
     background: #fff;
     border-bottom: 0px solid transparent;
}
 .faq-right-box .btn-link::before {
     content: "-";
     line-height: 30px;
     position: absolute;
     right: 43px;
     top: 24px;
     font-size: 24px;
     font-size: 20px;
}
 .faq-right-box .btn-link.collapsed::before {
     content: "+";
}
 .faq-right-box .card-body {
     -webkit-box-flex: 1;
     -ms-flex: 1 1 auto;
     flex: 1 1 auto;
     padding: 0px 30px;
     padding-top: 0;
     padding-bottom: 22px;
     border: 2px solid $main-color;
     background: #fff;
     border-top: 0;
}
 .faq-right-box .card-body p {
     margin: 0;
     font-size: 14px;
}
 .faq-right-box .btn-link.collapsed {
     border: 2px solid #e7e6ff;
}


#faq {
    padding: 3rem 0 !important;
  
    .mbr-iconfont {
      font-family: "Moririse2" !important;
      display: inline-flex;
      // -webkit-transform-origin: ;
      // transform-origin: ;
      // -webkit-transform: rotate(45deg) !important;
      // transform: rotate(45deg) !important;
      -webkit-transition: all 0.3s !important;
      transition: all 0.3s !important;
      padding: 0 !important;
      font-size: 1.4rem !important;
      color: $main-color;
    }
  
    .mbr-iconfont:before {
      -webkit-transform: scaleY(0) !important;
      transform: scaleY(0) !important;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
    }
    .collapsed {
      .mbr-iconfont {
        -webkit-transform: rotate(0deg)!important;
        transform: rotate(0deg) !important;
        // color:white !important;
      }
      .mbr-iconfont:before {
        -webkit-transform: scaleY(1) !important;
        transform: scaleY(1) !important;
        -webkit-transition: all 0.3s !important;
        transition: all 0.3s !important;
      }
    }
  
    .row{
      padding:1rem !important;
    }
    h4:hover{
      color:$main-color !important;
      .sign {
        padding-right: 1rem;
        line-height: 1.6 !important;
      }
    }
  
    .card{
      padding:5px 0;
      background:transparent;
      border:none;
      display: -webkit-flex;
      border-radius: 0px;
      border-bottom: 2px solid $light-medium-color !important;
  
      
      .card-header {
        border-radius: 0px;
        border: 0px;
        background-color: transparent;
        padding: 0;
        
        a{
          .panel-title{
            .toggle-icon {
              right: 7px;
              position: absolute;
              top: 36px;
              -webkit-transform: none;
              transform: none;
              display: inline-block;
              height: 2px;
              margin-left: 0;
              width: 12px;
            }
  
            .toggle-icon:before,
            .toggle-icon:after {
              background: currentColor;
              border: 0;
              box-sizing: border-box;
              content: "";
              display: block;
              left: 50%;
              position: absolute;
              top: 50%;
            }
  
            .toggle-icon:before {
              height: 12px;
              margin-left: -1px !important;
              width: 2px;
              margin-top: -6px;
              background-color: currentColor;
              border: 0;
              -webkit-transform: none;
              transform: none;
            }
            .toggle-icon:after {
              height: 2px;
              width: 12px;
              margin-left: -6px;
              margin-top: -1px;
            }
  
  
          }
        }
  
        .panel-title {
          margin-bottom: 0;
          display: flex;
          width: 100%;
          justify-content: space-between;
  
          // .mbr-iconfont {
          //   font-family: "Moririse2" !important;
          //   display: inline-flex;
          //   transform: rotate(45deg);
          //   transition: all 0.3s;
          //   padding: 0 !important;
          //   font-size: 1.4rem !important;
          //   color: $main-color;
          // }
  
          .toggle-icon {
            right: 7px;
            position: absolute;
            top: 36px;
            -webkit-transform: none;
            transform: none;
            display: inline-block;
            height: 2px;
            margin-left: 0;
            width: 12px;
          }
          .toggle-icon:before{
            height: 12px;
            margin-left: -1px !important;
            width: 2px;
            margin-top: -6px;
            background-color: currentColor;
            border: 0;
            -webkit-transform: none;
            transform: none;
          }
          .toggle-icon:after{
            height: 2px;
            width: 12px;
            margin-left: -6px;
            margin-top: -1px;
          }
  
          .toggle-icon:before,
          .toggle-icon:after {
            background: currentColor;
            border: 0;
            box-sizing: border-box;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
          }
  
          h4{
            padding: 1.2rem 0rem;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: -0.5px;
            justify-content: flex-start;
            width: 100%;
            color: #28303f;
            .sign {
              padding-right: 1rem;
              line-height: 1.6 !important;
            }
  
          }
        }
        .panel-title:hover{
          text-decoration:none;
          // color:#000;
        }
        .text-black{
          color:#000;
        }
        .display-7 {
          font-family: 'Muli', sans-serif;
          font-size: 1.125rem;
        }
  
      }
  
      .panel-body{
        padding:14px 20px;
        p {
          color:#6d7a8c;
          line-height: 1.5;
        }
        .display-4 {
          font-size: 16px;
          font-family: 'Muli', sans-serif;
        }
        .mbr-fonts-style{
  
        }
      }
    }
    
  }