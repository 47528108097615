.sick-note-container {
  padding: 100px;
}

.payment {
  padding: 100px;
}

.checkout-form {
  &__card {
    border: 1px solid black;
    max-width: 500px;
    padding: 18px 7px;
    border-radius: 5px;
  }
  &__buttons {
    max-width: 500px;
    &__button {
      width: 100%;
    }
  }
}

.payment__fee {
  font-size: 1.5rem;
}

.floating-btn-container {
  position: fixed;
  bottom: 10px;
  right: 0px;
  z-index: 1000 !important;
  width: 370px;
  .hide-on-mobile {
    display: inline;
  }
  .show-on-mobile {
    display: none;
  }
}

.no-icon-btn {
  padding-left: 50px;
  padding-right: 50px;
}

.center {
  text-align: center;
}
.sample-sicknote,
.sicknote-faq {
  padding: 100px 0;
}
.sample-sicknote-container {
  padding: 0 200px;
  text-align: center;
}

.price-list-sicknote {
  padding: 100px 0;
}

.sicknote-faq-container {
  .mbr-iconfont {
    font-family: "Moririse2" !important;
    display: inline-flex;
    // -webkit-transform-origin: ;
    // transform-origin: ;
    // -webkit-transform: rotate(45deg) !important;
    // transform: rotate(45deg) !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
    padding: 0 !important;
    font-size: 1.4rem !important;
    color: $main-color;
  }

  .mbr-iconfont:before {
    -webkit-transform: scaleY(0) !important;
    transform: scaleY(0) !important;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .collapsed {
    .mbr-iconfont {
      -webkit-transform: rotate(0deg) !important;
      transform: rotate(0deg) !important;
      // color:white !important;
    }
    .mbr-iconfont:before {
      -webkit-transform: scaleY(1) !important;
      transform: scaleY(1) !important;
      -webkit-transition: all 0.3s !important;
      transition: all 0.3s !important;
    }
  }

  .row {
    padding: 1rem !important;
  }
  h4:hover {
    color: $main-color !important;
    .sign {
      padding-right: 1rem;
      line-height: 1.6 !important;
    }
  }

  .card {
    padding: 5px 0;
    background: transparent;
    border: none;
    display: -webkit-flex;
    border-radius: 0px;
    border-bottom: 2px solid $light-medium-color !important;

    .card-header {
      border-radius: 0px;
      border: 0px;
      background-color: transparent;
      padding: 0;

      a {
        .panel-title {
          .toggle-icon {
            right: 7px;
            position: absolute;
            top: 36px;
            -webkit-transform: none;
            transform: none;
            display: inline-block;
            height: 2px;
            margin-left: 0;
            width: 12px;
          }

          .toggle-icon:before,
          .toggle-icon:after {
            background: currentColor;
            border: 0;
            box-sizing: border-box;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
          }

          .toggle-icon:before {
            height: 12px;
            margin-left: -1px !important;
            width: 2px;
            margin-top: -6px;
            background-color: currentColor;
            border: 0;
            -webkit-transform: none;
            transform: none;
          }
          .toggle-icon:after {
            height: 2px;
            width: 12px;
            margin-left: -6px;
            margin-top: -1px;
          }
        }
      }

      .panel-title {
        margin-bottom: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;

        // .mbr-iconfont {
        //   font-family: "Moririse2" !important;
        //   display: inline-flex;
        //   transform: rotate(45deg);
        //   transition: all 0.3s;
        //   padding: 0 !important;
        //   font-size: 1.4rem !important;
        //   color: $main-color;
        // }

        .toggle-icon {
          right: 7px;
          position: absolute;
          top: 36px;
          -webkit-transform: none;
          transform: none;
          display: inline-block;
          height: 2px;
          margin-left: 0;
          width: 12px;
        }
        .toggle-icon:before {
          height: 12px;
          margin-left: -1px !important;
          width: 2px;
          margin-top: -6px;
          background-color: currentColor;
          border: 0;
          -webkit-transform: none;
          transform: none;
        }
        .toggle-icon:after {
          height: 2px;
          width: 12px;
          margin-left: -6px;
          margin-top: -1px;
        }

        .toggle-icon:before,
        .toggle-icon:after {
          background: currentColor;
          border: 0;
          box-sizing: border-box;
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
        }

        h4 {
          padding: 1.2rem 0rem;
          margin-bottom: 0;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.5px;
          justify-content: flex-start;
          width: 100%;
          color: #28303f;
          .sign {
            padding-right: 1rem;
            line-height: 1.6 !important;
          }
        }
      }
      .panel-title:hover {
        text-decoration: none;
        // color:#000;
      }
      .text-black {
        color: #000;
      }
      .display-7 {
        font-family: "Muli", sans-serif;
        font-size: 1.125rem;
      }
    }

    .panel-body {
      padding: 14px 20px;
      p {
        color: #6d7a8c;
        line-height: 1.5;
      }
      .display-4 {
        font-size: 16px;
        font-family: "Muli", sans-serif;
      }
    }
  }
}

@media (max-width: 767px) {
  .sample-sicknote-container {
    padding: 0 0 !important;
  }
}
