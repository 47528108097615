#account-container {
    padding:2rem;
    input,
    select {
        width: 100%;
        height: 60px;
        border: 2px solid #eaedff;
        color: #6f7172;
        padding: 0 20px;
        margin-bottom: 20px;
    }

    label {
        color: #222;
        display: block;
    }

    label span {
        color: #fe4536;
    }

    .register-action input {
        width: inherit;
        height: auto;
    }

    .register-action label {
        display: inline-block;
        margin-left: 5px;
    }
}