#spinning-control {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    opacity: 0.9;
    z-index: 10000;

    .text {
        position: absolute;
        top: 58%;
        color: $main-color;
        // color: black;

        font-size: 18px;
        // padding-left: 20px
    }

}